<template>
    <div class="CEESAR-Card">
        <div class="CEESAR-CardTitle" v-if="title != undefined">{{title}}</div>
        
        <div class="CEESAR-CardContent">
            <slot />
        </div>

        <div class="CEESAR-CardButtons">
            <slot name="Buttons"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "CEESAR-Card",
    props:{
        title:{type: String, default: undefined}
    }
}
</script>

<style>

</style>